import React from "react"
import { Supplier } from "../../../../../../sharedTypes"
import { PreferredSupplierLearnMoreLink } from "./PreferredSupplierLearnMoreLink"

interface SelectedSupplierProps {
  selectedSupplier: Supplier
}

export const SelectedSupplier = ({
  selectedSupplier,
}: SelectedSupplierProps) => {
  return (
    <div>
      <p>
        <strong>{selectedSupplier.name}</strong> is the{" "}
        <strong>Health Plan Preferred Supplier</strong> of your patient&apos;s
        health plan.
      </p>
      <p>
        Health Plan Preferred Suppliers are likely to accept and fulfill the
        order faster. <PreferredSupplierLearnMoreLink />
      </p>
    </div>
  )
}
