import { Option } from "components/form/Select"
import { ConsignmentCloset } from "sharedTypes"
import { PackageSku } from "../sharedTypes"

export const closetLabel = (closet: ConsignmentCloset): string => {
  if (closet.floor) {
    return `Floor ${closet.floor} - ${closet.name}`
  } else {
    return closet.name
  }
}

export const productSuggestionTemplate = ({
  name,
  description,
}: {
  name: string
  description: string
}): string => {
  return `${name} - ${description}`
}

export const convertToOption = (productSuggestion: PackageSku): Option => ({
  label: productSuggestionTemplate(productSuggestion),
  value: `${productSuggestion.catalogPackageId}_${productSuggestion.catalogProductVariationId}`,
  ...productSuggestion,
})

export const meetsMinLength = (value: string, minLength: number): boolean => {
  const whitespaceRegex = /\s/g

  return (
    value.length >= minLength &&
    value.replace(whitespaceRegex, "").length >= minLength
  )
}
