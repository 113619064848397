// @team @facilitators
// @ts-strict-ignore
import React, { useEffect, useState } from "react"
import { ServiceTier, Supplier } from "sharedTypes"
import { PackageConfiguration } from "../../sharedTypes"
import { BasicSupplierHelpUrl } from "applications/Workflow/urls/external"
import { EventCategory, trackEvent } from "utilities/tracking"
import { showSupplierSelector } from "../../utilities/supplierSelector"
import { fetchSuppliers } from "./api"
import PreferredSupplierSelector from "./PreferredSupplierSelector"
import YourOrganizationsSuppliers from "./YourOrganizationsSuppliers"
import OtherSuppliersOnParachute from "./OtherSuppliersOnParachute"
import SelectedSupplierSummary from "../SupplierSelector/SelectedSupplierSummary"
import { useFeatureFlags } from "../../../../../../components/FeatureFlagContext"

type Props = {
  packageConfiguration: PackageConfiguration
  preferredSuppliers: Supplier[]
  redirectToPreferredSupplier: boolean
  yourOrganizationsSuppliersOnly: boolean
  flexibleSupplierAccess: boolean
  loading: boolean
  setLoading: (loading: boolean) => void
  manageFulfillmentAgreements: boolean
  showServiceTier: boolean
}

const SearchByProductSupplierSelector: React.FC<Props> = ({
  packageConfiguration,
  preferredSuppliers,
  redirectToPreferredSupplier,
  yourOrganizationsSuppliersOnly,
  flexibleSupplierAccess,
  loading,
  setLoading,
  manageFulfillmentAgreements,
  showServiceTier,
}) => {
  const { isFeatureEnabled } = useFeatureFlags()
  const isExplicitSupplierSelectionStepEnabled = isFeatureEnabled(
    "explicitSupplierSelectionStep"
  )

  const { availableSuppliers, supplier, fulfillable } = packageConfiguration
  const [selectedSupplier, setSelectedSupplier] = useState(
    supplier &&
      availableSuppliers.find((s) => s.externalId === supplier.externalId)
  )

  const initialShowSupplierSelect = showSupplierSelector(
    selectedSupplier,
    availableSuppliers,
    fulfillable
  )
  const [showSupplierSelect, setShowSupplierSelect] = useState(
    redirectToPreferredSupplier || initialShowSupplierSelect
  )

  const [yourOrganizationsSuppliers, setYourOrganizationsSuppliers] = useState<
    Supplier[]
  >([])
  const [otherSuppliersOnParachute, setOtherSuppliersOnParachute] = useState<
    Supplier[]
  >([])

  const preferredSupplierIds = preferredSuppliers.map((ps) => ps.externalId)

  const hasPreferredSuppliers = preferredSupplierIds.length > 0

  const preferredSupplierSelected = preferredSupplierIds.includes(
    selectedSupplier?.externalId
  )

  const otherSupplierOnParachuteSelected = otherSuppliersOnParachute
    .map((s) => s.externalId)
    .includes(selectedSupplier?.externalId)

  useEffect(() => {
    if (preferredSupplierSelected) {
      trackEvent(EventCategory.PreferredSupplier, "autoselect")
    }
  }, [preferredSupplierSelected])

  useEffect(() => {
    if (!preferredSupplierSelected) {
      setLoading(true)
      fetchSuppliers(
        packageConfiguration.packageId,
        yourOrganizationsSuppliersOnly
      )
        .then((data) => {
          setYourOrganizationsSuppliers(data.yourOrganizationsSuppliers)
          setOtherSuppliersOnParachute(data.otherSuppliers)
        })
        .finally(() => setLoading(false))
    }
  }, [
    packageConfiguration,
    preferredSupplierSelected,
    yourOrganizationsSuppliersOnly,
    setLoading,
  ])

  const onSupplierSelect = (event) => {
    const supplierExternalId = event.currentTarget.value

    const supplierSelection = availableSuppliers.find(
      (s) => s.externalId === supplierExternalId
    )
    setSelectedSupplier(supplierSelection)
    setShowSupplierSelect(false)

    void trackEvent(
      EventCategory.ProductConfiguration,
      "supplier-radio-clicked"
    )
  }

  const hideOtherSuppliersSection =
    yourOrganizationsSuppliersOnly ||
    !flexibleSupplierAccess ||
    otherSuppliersOnParachute.length < 1

  if (!showSupplierSelect && !isExplicitSupplierSelectionStepEnabled) {
    return (
      <SelectedSupplierSummary
        selectedSupplier={selectedSupplier}
        availableSuppliers={availableSuppliers}
        preferredSuppliers={preferredSuppliers}
        showSupplierSelect={() => setShowSupplierSelect(true)}
      />
    )
  }

  return (
    <>
      {hasPreferredSuppliers && (
        <PreferredSupplierSelector
          availableSuppliers={availableSuppliers}
          preferredSuppliers={preferredSuppliers}
          onSupplierSelect={onSupplierSelect}
        />
      )}

      {!hasPreferredSuppliers && !loading && (
        <>
          <YourOrganizationsSuppliers
            manageFulfillmentAgreements={manageFulfillmentAgreements}
            onSupplierSelect={onSupplierSelect}
            yourOrganizationsSuppliers={yourOrganizationsSuppliers}
            showServiceTier={showServiceTier}
            preferredSupplierIds={preferredSupplierIds}
            hasPreferredSuppliers={hasPreferredSuppliers}
          />
          {hideOtherSuppliersSection ? null : (
            <OtherSuppliersOnParachute
              onSupplierSelect={onSupplierSelect}
              otherSuppliersOnParachute={otherSuppliersOnParachute}
              initiallyShowOtherSuppliers={
                yourOrganizationsSuppliers.length === 0 ||
                otherSupplierOnParachuteSelected
              }
            />
          )}
        </>
      )}

      {selectedSupplier && selectedSupplier.serviceTier === ServiceTier.Basic && (
        <div className="well well-warning well-expand">
          {selectedSupplier.name} does not have messaging and status updates
          enabled on Parachute.&nbsp;
          <a href={BasicSupplierHelpUrl} target="_blank" rel="noreferrer">
            Learn More
          </a>
        </div>
      )}
    </>
  )
}

export default SearchByProductSupplierSelector
