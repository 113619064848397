import { OfferingType } from "sharedTypes"
import { PackageConfigurationStep } from "../sharedTypes"

export function getModalTitle(
  step?: PackageConfigurationStep,
  offeringType?: OfferingType
): string {
  switch (step) {
    case PackageConfigurationStep.SupplierStep:
      return "Select Supplier"
    case PackageConfigurationStep.ConfigurationStep:
      return "Configuration"
    case PackageConfigurationStep.RequirementsStep:
      return "Requirements"
    case PackageConfigurationStep.DetailsStep:
      return "Details"
    case PackageConfigurationStep.RxDetailsStep:
      return offeringType === OfferingType.Service
        ? "Service Details"
        : "Prescription Details"
    default:
      return summaryStepTitle(offeringType)
  }
}

const summaryStepTitle = (offeringType?: OfferingType): string => {
  switch (offeringType) {
    case OfferingType.Service:
      return "Service Summary"
    case OfferingType.O2Recert:
      return "Document Summary"
    default:
      return "Product Summary"
  }
}
