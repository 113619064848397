import React, { useContext } from "react"
import GlobalContext from "context/Global"
import Overlay from "components/Overlay"
import ProductSummary from "./ProductSummary/ProductSummary"
import Requirements from "./Requirements"
import RxDetails from "./RxDetails"
import Details from "./Details"
import {
  isConfigurationIncomplete,
  showAccessories,
  isSupplierIncomplete,
} from "../utilities"
import {
  DmeOrder,
  Employer,
  Supplier,
  SurveyQuestion,
  SurveyAnswerType,
} from "sharedTypes"
import { PackageConfiguration, PackageConfigurationStep } from "../sharedTypes"
import { FormikValues } from "formik"
import SelectSupplier from "./SelectSupplier"
import Configuration from "./Configuration"

type Props = {
  answerRxSurveyQuestion(
    question: SurveyQuestion,
    answerType: SurveyAnswerType,
    answerValue: string
  ): Promise<void>
  currentEmployer: Employer
  dmeOrder: DmeOrder
  packageConfiguration: PackageConfiguration
  useFeetForHeight: boolean
  loading: boolean
  remove(): void
  returnToBrowse(): void
  update(params: FormikValues): Promise<PackageConfiguration> | Promise<void>
  save(params: FormikValues): Promise<PackageConfiguration> | Promise<void>
  step: string | PackageConfigurationStep
  changeStep(step: PackageConfigurationStep): void
  recalculateStep(): void
  changeHeightUnit(): void
  goToNextPage(params: FormikValues): void
  showServiceTier: boolean
  preferredSuppliers: Supplier[]
  redirectToPreferredSupplier?: boolean
  yourOrganizationsSuppliersOnly: boolean
}

const InternalPackageConfiguration = (props: Props): React.ReactElement => {
  const {
    answerRxSurveyQuestion,
    changeHeightUnit,
    changeStep,
    currentEmployer,
    dmeOrder,
    goToNextPage,
    loading,
    packageConfiguration,
    preferredSuppliers,
    recalculateStep,
    remove,
    returnToBrowse,
    save,
    showServiceTier,
    step,
    update,
    useFeetForHeight,
    redirectToPreferredSupplier = false,
    yourOrganizationsSuppliersOnly,
  } = props
  const { featureFlags } = useContext(GlobalContext)

  const formularyPriceEnabled = !!(
    dmeOrder.clinicalFacility.formularyPriceEnabled &&
    featureFlags.formularyPricing
  )

  const onContinue = (params: FormikValues) => {
    if (params) {
      return update(params)
    } else {
      recalculateStep()
      return Promise.resolve(packageConfiguration)
    }
  }

  const onEditSupplier = () => {
    changeStep(PackageConfigurationStep.SupplierStep)
  }

  const onEditRequirements = () => {
    changeStep(PackageConfigurationStep.RequirementsStep)
  }

  const onEditRxDetails = () => {
    changeStep(PackageConfigurationStep.RxDetailsStep)
  }

  const onEditDetails = () => {
    changeStep(PackageConfigurationStep.DetailsStep)
  }

  const onEditConfiguration = () => {
    changeStep(PackageConfigurationStep.ConfigurationStep)
  }

  const currentStep = () => {
    switch (step) {
      case PackageConfigurationStep.SupplierStep:
        return (
          <SelectSupplier
            redirectToPreferredSupplier={redirectToPreferredSupplier}
            dmeOrder={dmeOrder}
            showServiceTier={showServiceTier}
            isSupplierIncomplete={isSupplierIncomplete(packageConfiguration)}
            packageConfiguration={packageConfiguration}
            preferredSuppliers={preferredSuppliers}
            onContinue={onContinue}
            save={save}
            yourOrganizationsSuppliersOnly={yourOrganizationsSuppliersOnly}
          />
        )
      case PackageConfigurationStep.ConfigurationStep:
        return (
          <Configuration
            redirectToPreferredSupplier={redirectToPreferredSupplier}
            dmeOrder={dmeOrder}
            showAccessories={showAccessories(packageConfiguration)}
            showServiceTier={showServiceTier}
            isConfigurationIncomplete={isConfigurationIncomplete(
              packageConfiguration
            )}
            packageConfiguration={packageConfiguration}
            preferredSuppliers={preferredSuppliers}
            onContinue={onContinue}
            save={save}
            featureFlags={featureFlags}
            yourOrganizationsSuppliersOnly={yourOrganizationsSuppliersOnly}
            showSupplierSelection={onEditSupplier}
          />
        )

      case PackageConfigurationStep.RequirementsStep:
        return (
          <Requirements
            currentEmployer={currentEmployer}
            packageConfiguration={packageConfiguration}
            useFeetForHeight={useFeetForHeight}
            onSkip={returnToBrowse}
            onContinue={onContinue}
            changeHeightUnit={changeHeightUnit}
          />
        )
      case PackageConfigurationStep.RxDetailsStep:
        return (
          <RxDetails
            answerQuestion={answerRxSurveyQuestion}
            rxDetails={packageConfiguration.rxDetails}
            onContinue={onContinue}
          />
        )
      case PackageConfigurationStep.DetailsStep:
        return (
          <Details
            packageConfiguration={packageConfiguration}
            onContinue={onContinue}
          />
        )
      default:
        return (
          <ProductSummary
            packageConfiguration={packageConfiguration}
            formularyPriceEnabled={formularyPriceEnabled}
            explicitSupplierSelectionStepFeatureEnabled={
              !!featureFlags.explicitSupplierSelectionStep
            }
            onEditSupplier={onEditSupplier}
            onEditDetails={onEditDetails}
            onEditRequirements={onEditRequirements}
            onEditRxDetails={onEditRxDetails}
            onEditConfiguration={onEditConfiguration}
            returnToBrowse={returnToBrowse}
            remove={remove}
            update={update}
            useFeetForHeight={useFeetForHeight}
            goToNextPage={goToNextPage}
          />
        )
    }
  }

  return (
    <Overlay active={loading} showSpinner>
      <div>{currentStep()}</div>
    </Overlay>
  )
}

export default InternalPackageConfiguration
