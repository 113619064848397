import React, { useContext } from "react"
import {
  FilterConfig,
  FilterOptions,
  FilterValues,
  FilterType,
  DateRangeConfig,
  SelectConfig,
  ToggleConfig,
} from "./sharedTypes"
import { DashboardView } from "../../sharedTypes"
import DateRangeFilter from "components/input/DateRangeFilter"
import SelectFilter from "components/SelectFilter"
import ToggleFilter from "components/ToggleFilter"
import Search from "./Search"
import { ActionType, ViewContext } from "../../view/viewContext"

interface Props {
  filterConfigs: FilterConfig[]
  currentView: DashboardView
  filterOptions: FilterOptions
  onChange(filterValues: FilterValues): Promise<void>
  onQueryChange(query: string): void
  dirtyFilters: { [key: string]: boolean }
}

const InternalDashboardFilters = (props: Props) => {
  const {
    filterConfigs,
    currentView,
    filterOptions,
    onChange,
    onQueryChange,
    dirtyFilters,
  } = props
  const { filterSettings, filterValues } = currentView

  const { dispatch } = useContext(ViewContext)

  function buildSelectFilter<T>(selectConfig: SelectConfig<T>) {
    const { name, label, fetchOptions, buildOption } = selectConfig

    return (
      <SelectFilter
        key={name}
        name={name}
        label={label}
        initialValues={filterValues[name]}
        options={filterOptions[name]?.map(buildOption)}
        fetchOptions={
          fetchOptions &&
          ((term) =>
            fetchOptions(term).then((r) => {
              const action = {
                type: ActionType.UPDATE_FILTER_OPTIONS,
                data: { name, options: r },
              }
              dispatch(action)
              return r.map(buildOption)
            }))
        }
        dirty={dirtyFilters[name]}
        onChange={onChange}
        enableReinitialize
      />
    )
  }

  function buildToggleFilter(config: ToggleConfig) {
    const { name, options } = config

    return (
      <ToggleFilter
        key={name}
        name={name}
        options={options}
        initialValue={filterValues[name]}
        onChange={onChange}
        dirty={dirtyFilters[name]}
      />
    )
  }

  function buildDateRangeFilter(dateRangeConfig: DateRangeConfig) {
    const { name, title } = dateRangeConfig

    return (
      <DateRangeFilter
        key={name}
        name={name}
        title={title}
        onChange={onChange}
        initialStartDate={filterValues[`${name}StartAt`]}
        initialEndDate={filterValues[`${name}EndAt`]}
        dirty={dirtyFilters[`${name}StartAt`] || dirtyFilters[`${name}EndAt`]}
        enableReinitialize
      />
    )
  }

  function buildFilter(filterConfig: FilterConfig) {
    if (filterSettings[`${filterConfig.name}Filter`]) {
      switch (filterConfig.filterType) {
        case FilterType.Select:
          return buildSelectFilter(filterConfig)
        case FilterType.Toggle:
          return buildToggleFilter(filterConfig)
        case FilterType.DateRange:
          return buildDateRangeFilter(filterConfig)
      }
    }
  }

  return (
    <>
      <Search
        onChange={onQueryChange}
        value={filterValues.query}
        placeholder="Search Patient or Order ID"
      />
      {filterConfigs.map(buildFilter)}
    </>
  )
}

export default InternalDashboardFilters
