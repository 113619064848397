import React, { useContext, useEffect } from "react"
import { useHistory, useParams } from "react-router-dom"
import { CatalogSeed } from "../../../../types/sharedTypes"
import {
  createCatalogSeed,
  getCatalogSeed,
  updateCatalogSeed,
} from "../../../../api/seeds"
import ContentArea from "../../../../components/ContentArea"
import { Backdrop, CircularProgress } from "@material-ui/core"
import { StaticFieldDefinition } from "applications/Cms/types/form/sharedTypes"
import { FormikErrors, FormikProps } from "formik"
import { Form } from "components/form"
import { CanopyButton } from "@parachutehealth/canopy-button"
import { AxiosError } from "axios"
import { isNullOrUndefined } from "../../../../../../utilities/isNullOrUndefined"
import { NoticeContext } from "../../../../contexts/NoticeContext"
import { seedDetailUrl, seedsUrl } from "../../../../urls/seeds"
import { CanopyTextInputField } from "@parachutehealth/canopy-text-input-field"
import { CanopyTextareaField } from "@parachutehealth/canopy-textarea-field"

const staticFields: StaticFieldDefinition[] = [
  {
    label: "Package External IDs",
    name: "catalogPackageExternalIds",
    type: "textarea",
  },
  {
    label: "Product Variation External IDs",
    name: "catalogProductVariationExternalIds",
    type: "textarea",
  },
  {
    label: "Internal Notes",
    name: "internalNotes",
    type: "textarea",
  },
]

const SeedEditPage: React.FC = () => {
  const { seedId } = useParams()
  const history = useHistory()
  const { showNotice } = useContext(NoticeContext)

  const [catalogSeed, setCatalogSeed] = React.useState<
    CatalogSeed | undefined
  >()

  const [loading, setLoading] = React.useState<boolean>(
    isNullOrUndefined(catalogSeed) && !isNullOrUndefined(seedId)
  )

  const loadSeed = async (seedId: string | number) => {
    const seed = await getCatalogSeed(seedId)
    setCatalogSeed(seed)
    setLoading(false)
  }

  const initialValues = {
    name: catalogSeed?.name || "",
    internalNotes: catalogSeed?.internalNotes || "",
    catalogPackageExternalIds:
      catalogSeed?.packages?.map((p) => p.externalId).join("\n") || "",
    catalogProductVariationExternalIds:
      catalogSeed?.productVariations?.map((p) => p.externalId).join("\n") || "",
  }

  useEffect(() => {
    if (seedId) {
      void loadSeed(seedId)
    }
  }, [seedId])

  const onCancel = (): void => {
    let url: string
    if (catalogSeed) {
      url = seedDetailUrl(catalogSeed.externalId)
    } else {
      url = seedsUrl()
    }
    history.push(url)
  }

  const createOrUpdate = async (
    params: Record<string, any>
  ): Promise<{ catalogSeed: CatalogSeed; message: string }> => {
    if (seedId) {
      return await updateCatalogSeed(seedId, params)
    } else {
      return await createCatalogSeed(params)
    }
  }

  const handleSubmit = async (
    params: Record<string, any>,
    { setErrors }: any
  ) => {
    await createOrUpdate({ catalogSeed: params })
      .then((data) => {
        if (data.catalogSeed) {
          history.push(seedDetailUrl(data.catalogSeed.externalId))
        } else {
          history.push(seedsUrl())
        }

        showNotice(
          data.message || "You've successfully updated that seed",
          "success"
        )
      })
      .catch(
        (
          error: AxiosError<{
            message?: string
            errors?: Record<string, string[]>
          }>
        ) => {
          if (error.response?.data?.message) {
            showNotice(error.response.data.message, "danger")
          }
          setErrors(error.response?.data?.errors)
        }
      )
  }

  const formTitle = isNullOrUndefined(seedId)
    ? "New Catalog Seed"
    : "Seed Settings"

  const errorMessage = (
    errors:
      | string
      | string[]
      | FormikErrors<any>
      | FormikErrors<any>[]
      | undefined
  ): string | undefined => {
    if (Array.isArray(errors)) {
      return errors.join(", ")
    } else if (typeof errors === "string") {
      return errors
    } else {
      return undefined
    }
  }

  return (
    <>
      <div className="canopy-typography-heading-xlarge canopy-mb-6x canopy-mt-2x">
        {formTitle}
      </div>
      <ContentArea variant="medium">
        <Form
          enableReinitialize
          initialValues={initialValues}
          onSubmit={handleSubmit}
        >
          {(formik: FormikProps<any>) => (
            <>
              <Backdrop style={{ color: "#fff", zIndex: 1_000 }} open={loading}>
                <CircularProgress color="inherit" />
              </Backdrop>

              <fieldset>
                <CanopyTextInputField
                  key="name"
                  label="Name"
                  name="name"
                  value={formik.values["name"]}
                  feedbackMessage={errorMessage(formik.errors["name"])}
                  className="canopy-mb-10x"
                  onChange={formik.handleChange}
                />
                {staticFields.map((field) => (
                  <CanopyTextareaField
                    className="canopy-mb-10x"
                    key={field.name}
                    value={formik.values[field.name]}
                    feedbackMessage={errorMessage(formik.errors[field.name])}
                    label={field.label}
                    name={field.name}
                    onChange={formik.handleChange}
                  />
                ))}
              </fieldset>

              <CanopyButton
                size="small"
                variant="primary"
                type="submit"
                loading={formik.isSubmitting}
              >
                Save
              </CanopyButton>
              <CanopyButton
                className="canopy-mis-4x"
                size="small"
                variant="tertiary"
                disabled={formik.isSubmitting}
                onClick={onCancel}
              >
                Cancel
              </CanopyButton>
            </>
          )}
        </Form>
      </ContentArea>
    </>
  )
}

export default SeedEditPage
