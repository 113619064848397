import React, { useContext, useState } from "react"
import { CanopyButton } from "@parachutehealth/canopy-button"
import { CanopyIcon } from "@parachutehealth/canopy-icon"
import * as styles from "./index.module.scss"
import { Supplier } from "../../../../../../sharedTypes"
import { CanopyDialogModal } from "@parachutehealth/canopy-dialog-modal"
import { EventCategory, trackEvent } from "../../../../../../utilities/tracking"
import { PreferredSupplierContext } from "../../hooks/usePreferredSupplier"
import { SelectedSupplier } from "./SelectedSupplier"
import { SupplierSelectionForm } from "./SupplierSelectionForm"

interface Props {
  isOpen: boolean

  onClose(): void

  onSubmit(supplierId: string): void

  currentSupplier: Supplier | undefined
}

export const PreferredSupplierAlertModal: React.FC<Props> = ({
  isOpen,
  onClose,
  onSubmit,
  currentSupplier,
}) => {
  const { selectedSupplier, showDefaultSelectedSupplier } = useContext(
    PreferredSupplierContext
  )
  const [isRedirecting, setIsRedirecting] = useState(false)
  const [error, setError] = useState<string>()

  const headerTitle = `${
    currentSupplier ? currentSupplier.name : "The selected supplier"
  } may be unable to fulfill your
            order for this product.`
  const modalBody = () => {
    return showDefaultSelectedSupplier && !!selectedSupplier ? (
      <SelectedSupplier selectedSupplier={selectedSupplier.supplier} />
    ) : (
      <SupplierSelectionForm formError={error} />
    )
  }

  const modalFooter = () => (
    <>
      <CanopyButton
        fullWidth
        variant="warning"
        onClick={() => {
          if (!selectedSupplier) {
            setError("Please select a supplier")
            return
          }
          void trackEvent(
            EventCategory.SupplierSelection,
            "modal-redirect-to-preferred-supplier-clicked"
          )
          onSubmit(selectedSupplier.supplier.externalId || "")
          setIsRedirecting(true)
        }}
        loading={isRedirecting}
        loadingText="Redirecting..."
      >
        Redirect to Health Plan Preferred Supplier
      </CanopyButton>

      <CanopyButton
        fullWidth
        variant="tertiary"
        className={styles.tertiaryButton}
        onClick={() => {
          void trackEvent(
            EventCategory.SupplierSelection,
            "continue-with-supplier-clicked"
          )
          onClose()
        }}
      >
        {`Continue with ${currentSupplier?.name || "Current Supplier"}`}
      </CanopyButton>
    </>
  )

  return (
    <CanopyDialogModal
      open={isOpen}
      unclosable
      size="small"
      className={styles.alertModal}
      headerOverride={
        <div className="d-flex flex-column justify-content-center align-content-center text-center canopy-ml-20x">
          <span>
            <CanopyIcon
              name="shield-slash"
              size="large"
              fill="canopyColorPrimitivesYellow62"
            />
          </span>
          <h3>
            <strong>{headerTitle}</strong>
          </h3>
        </div>
      }
      footerOverride={modalFooter()}
    >
      {modalBody()}
    </CanopyDialogModal>
  )
}
