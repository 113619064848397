import React from "react"
import { GridColDef } from "@mui/x-data-grid-pro"
import formatDate from "date-fns/format"
import { dmeOrderUrl } from "../urls"
import Link from "components/Link"

export const incomingOrderColumnDefinitions = (): GridColDef[] => [
  {
    field: "action",
    headerName: "Action",
    width: 96,
    sortable: false,
    renderCell: (params) => (
      <Link
        aria-label={`View Order ${params.row.id} for ${params.row.patient.fullName}`}
        href={dmeOrderUrl(params.row.clinicalFacility.id, params.row.id)}
      >
        View
      </Link>
    ),
  },
  {
    field: "patientName",
    headerName: "Patient Name",
    flex: 1,
    valueGetter: (params) => params.row.patient.fullName,
  },
  {
    field: "dateOfBirth",
    headerName: "Date of Birth",
    flex: 1,
    valueGetter: (params) =>
      formatDate(params.row.patient.dateOfBirth, "MM/DD/YYYY"),
  },
  {
    field: "clinician",
    headerName: "Clinician",
    flex: 1,
    valueGetter: (params) => params.row.orderingDoctor?.name,
  },
  {
    field: "facility",
    headerName: "Facility",
    flex: 1,
    valueGetter: (params) => params.row.clinicalFacility.name,
  },
  {
    field: "productSubcategories",
    headerName: "Product Type",
    flex: 1,
    valueGetter: (params) =>
      params.row.productSubcategories
        .map((productSubcategory) => productSubcategory.name)
        .join(" • "),
  },
  {
    field: "supplier",
    headerName: "Supplier",
    flex: 1,
    valueGetter: (params) => params.row.supplier?.name,
  },
  {
    field: "deliveryDate",
    headerName: "Requested Delivery",
    flex: 1,
    valueGetter: (params) =>
      params.row.deliveryDate &&
      formatDate(params.row.deliveryDate, "MM/DD/YYYY"),
  },
]
