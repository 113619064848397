import * as React from "react"
import { GridRenderCellParams } from "@mui/x-data-grid-pro"
import { Category } from "applications/Cms/types/sharedTypes"

const renderCategories = (params: GridRenderCellParams): JSX.Element => {
  return (
    <>
      {params.row[params.field]?.map((category: Category, i: number) => [
        i > 0 && <span key={i}>&nbsp;&bull;&nbsp;</span>,
        <a className="category" key={category.url} href={category.url}>
          {category.name}
        </a>,
      ])}
    </>
  )
}

export default renderCategories
