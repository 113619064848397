import React from "react"

export const PreferredSupplierLearnMoreLink = () => (
  <a
    href="/Selecting Health Plan Preferred Supplier 4 2025.pdf"
    target="_blank"
    aria-label="Learn more about Health Plan Preferred Suppliers"
  >
    Learn more
  </a>
)
