// @team @catalog-crew

export const consignmentClosetsUrl = (): string => {
  return "/cms/catalog/consignment_closets"
}

export const consignmentClosetCreateUrl = (): string => {
  return `${consignmentClosetsUrl()}/new`
}

export const consignmentClosetDetailUrl = (
  consignmentClosetId: string
): string => {
  return `/cms/catalog/consignment_closets/${consignmentClosetId}`
}
