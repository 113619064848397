import React, { useCallback, useEffect, useState } from "react"
import { useFeatureFlags } from "components/FeatureFlagContext"
import Modal from "components/Modal"
import { handleError } from "utilities/error"
import {
  getPackageConfiguration as getPackageConfigurationV1,
  updatePackageConfiguration,
  deletePackageConfiguration,
  getPackageConfigurationV2,
} from "../../api"
import InternalPackageConfiguration from "./components/InternalPackageConfiguration"
import { getModalTitle } from "./utilities/modal"
import * as utilities from "./utilities"
import {
  fetchInitialPackageConfigurationData,
  updateEmploymentPreferences,
  answerRxSurveyQuestion,
} from "./api"
import withInitialData from "components/withInitialData"

import {
  DmeOrder,
  Employer,
  History,
  SurveyQuestion,
  SurveyAnswerType,
  EmployerType,
  ApplicationError,
} from "sharedTypes"
import {
  PackageConfigurationStep,
  Preferences,
  PackageConfiguration as PackageConfigurationData,
} from "./sharedTypes"

import { PreferredSupplierAlertModal } from "./components/PreferredSupplierAlertModal/PreferredSupplierAlertModal"
import Overlay from "../../../../components/Overlay"
import * as routes from "../../routes"
import {
  PreferredSupplierContext,
  usePreferredSupplier,
} from "./hooks/usePreferredSupplier"

type InitialData = {
  preferences: Preferences
  showServiceTier: boolean
}

type Props = {
  currentEmployer: Employer
  dmeOrder: DmeOrder
  packageConfigurationId: string
  returnToBrowse(): Promise<void>
  refreshDmeOrder(): Promise<void>
  history: History
  initialData: InitialData
  goToNextPage(): Promise<void>
  supplierId?: string
  forceShowRxDetails: boolean
  initialSelection: boolean
  yourOrganizationsSuppliersOnly: boolean
  searchWorkflow: string
}

const PackageConfiguration: React.FC<Props> = ({
  initialData,
  currentEmployer,
  packageConfigurationId,
  initialSelection,
  forceShowRxDetails,
  dmeOrder,
  refreshDmeOrder,
  goToNextPage,
  returnToBrowse,
  supplierId,
  history,
  yourOrganizationsSuppliersOnly,
  searchWorkflow,
}) => {
  const { isFeatureEnabled } = useFeatureFlags()
  const isMarketplacePackageConfigurationEnabled = isFeatureEnabled(
    "marketplacePackageConfiguration"
  )
  const isExplicitSupplierSelectionStepEnabled = isFeatureEnabled(
    "explicitSupplierSelectionStep"
  )
  const checkPreferredSuppliers =
    currentEmployer.employerType === EmployerType.ClinicalFacility

  const [packageConfiguration, setPackageConfiguration] = useState<
    PackageConfigurationData
  >()
  const [step, setStep] = useState<PackageConfigurationStep>()
  const [visitedRxDetails, setVisitedRxDetails] = useState(false)
  const [visitedPackageConfig, setVisitedPackageConfig] = useState(false)
  const [useFeetForHeight, setUseFeetForHeight] = useState(
    initialData?.preferences?.useFeetForHeight || false
  )

  const [
    dismissPreferredSupplierAlert,
    setDismissPreferredSupplierAlert,
  ] = useState(false)
  const [
    redirectToPreferredSupplier,
    setRedirectToPreferredSupplier,
  ] = useState<boolean>(false)

  const [configLoading, setConfigLoading] = useState(false)

  const calculateStep = useCallback(
    (packageConfig: PackageConfigurationData) => {
      const forceShowPackageConfiguration =
        initialSelection && packageConfig.optionalProducts.length > 0
      const packageConfigStep = utilities.calculateStep(
        packageConfig,
        forceShowRxDetails && !visitedRxDetails,
        forceShowPackageConfiguration && !visitedPackageConfig,
        isExplicitSupplierSelectionStepEnabled
      )
      setStep(packageConfigStep)
      setVisitedRxDetails(
        visitedRxDetails ||
          packageConfigStep === PackageConfigurationStep.RxDetailsStep
      )
      setVisitedPackageConfig(
        visitedPackageConfig ||
          packageConfigStep === PackageConfigurationStep.ConfigurationStep
      )
    },
    [
      forceShowRxDetails,
      initialSelection,
      visitedPackageConfig,
      visitedRxDetails,
      isExplicitSupplierSelectionStepEnabled,
    ]
  )

  const loadPackageConfig = useCallback(async () => {
    setConfigLoading(true)
    try {
      const getPackageConfiguration = isMarketplacePackageConfigurationEnabled
        ? getPackageConfigurationV2
        : getPackageConfigurationV1
      const packageConfigResponse = await getPackageConfiguration(
        packageConfigurationId,
        searchWorkflow
      )
      setPackageConfiguration(packageConfigResponse.data)
      calculateStep(packageConfigResponse.data)
      setConfigLoading(false)

      return packageConfigResponse.data
    } catch (e) {
      setConfigLoading(false)

      handleError(e as ApplicationError)
    }
  }, [
    packageConfigurationId,
    calculateStep,
    isMarketplacePackageConfigurationEnabled,
    searchWorkflow,
  ])
  const {
    loadPreferredSuppliers,
    preferredSuppliers,
    loading: preferredSuppliersLoading,
    selectedSupplier,
    showDefaultSelectedSupplier,
    setSelectedSupplier,
  } = usePreferredSupplier(dmeOrder)

  const remove = async () => {
    setConfigLoading(true)
    await deletePackageConfiguration(packageConfigurationId)
    await refreshDmeOrder()
    history.push(routes.productsPath(supplierId))
  }

  const update = async (params) => {
    try {
      const response = await updatePackageConfiguration(
        packageConfigurationId,
        params,
        searchWorkflow
      )
      setPackageConfiguration(response.data)
      calculateStep(response.data)
    } catch (e) {
      handleError(e as ApplicationError)
    }
  }
  const save = async (params) => {
    try {
      const response = await updatePackageConfiguration(
        packageConfigurationId,
        params,
        searchWorkflow
      )
      const newPackageConfiguration = response.data
      const configurationIsNewlyValid =
        !packageConfiguration?.supplier &&
        newPackageConfiguration.configured &&
        newPackageConfiguration.optionalProducts.length ===
          newPackageConfiguration.selectedOptionalProductIds.length

      setPackageConfiguration(newPackageConfiguration)

      if (configurationIsNewlyValid) {
        calculateStep(newPackageConfiguration)
      }
    } catch (e) {
      handleError(e as ApplicationError)
    }
  }

  const nextPage = async () => {
    setConfigLoading(true)

    try {
      await goToNextPage()
    } catch (e) {
      setConfigLoading(false)

      handleError(e as ApplicationError)
    }
  }

  const changeHeightUnit = () => {
    setUseFeetForHeight(!useFeetForHeight)
    updateEmploymentPreferences({
      employmentPreference: { useFeetForHeight },
    })
  }

  const onCancel = async () => {
    if (packageConfiguration && !packageConfiguration.configured) {
      history.push(routes.productsPath(supplierId))
      await deletePackageConfiguration(packageConfigurationId)
      await refreshDmeOrder()
    } else {
      await returnToBrowse()
    }
  }

  const submitRxSurveyQuestion = async (
    question: SurveyQuestion,
    answerType: SurveyAnswerType,
    answerValue: string
  ) => {
    if (!packageConfiguration) return
    try {
      const response = await answerRxSurveyQuestion(
        packageConfiguration.id,
        question,
        answerType,
        answerValue
      )
      await refreshDmeOrder()
      setPackageConfiguration((prev) => {
        if (prev) {
          return {
            ...prev,
            rxDetails: response,
          }
        }
      })
    } catch (e) {
      handleError(e as ApplicationError)
    }
  }

  useEffect(() => {
    if (!packageConfiguration) {
      void loadPackageConfig().then((data) => {
        if (checkPreferredSuppliers) {
          void loadPreferredSuppliers(data)
        }
      })
    }
  }, [
    packageConfiguration,
    loadPackageConfig,
    checkPreferredSuppliers,
    loadPreferredSuppliers,
  ])

  useEffect(() => {
    const selectedSupplierIsPreferred =
      preferredSuppliers.filter(
        (ps) =>
          ps.supplier.externalId === packageConfiguration?.supplier?.externalId
      ).length > 0
    setDismissPreferredSupplierAlert(
      !packageConfiguration?.supplier ||
        selectedSupplierIsPreferred ||
        !preferredSuppliers.length
    )
    // Disabling with ES Lint because we don't want to update setDismissPreferredSupplierAlert if the package config updates
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preferredSuppliers])

  const loadingData = preferredSuppliersLoading || configLoading
  const showPreferredSupplierModal =
    !loadingData &&
    !!packageConfiguration &&
    preferredSuppliers.length > 0 &&
    !dismissPreferredSupplierAlert

  const showPreferredSupplierAlertModal = (showModal: boolean) => {
    return (
      <PreferredSupplierAlertModal
        currentSupplier={packageConfiguration?.supplier}
        isOpen={showModal}
        onClose={() => setDismissPreferredSupplierAlert(true)}
        onSubmit={async (supplierId: string) => {
          if (supplierId) {
            await save({ supplierId })
          }
          setRedirectToPreferredSupplier(true)
          setDismissPreferredSupplierAlert(true)
        }}
      />
    )
  }

  if (configLoading || preferredSuppliersLoading) {
    return <Overlay showSpinner={true} active={true} />
  }

  return (
    <PreferredSupplierContext.Provider
      value={{
        preferredSuppliers,
        preferredSuppliersLoading,
        selectedSupplier,
        showDefaultSelectedSupplier,
        setSelectedSupplier,
      }}
    >
      {showPreferredSupplierAlertModal(showPreferredSupplierModal)}
      <Modal
        title={getModalTitle(step, packageConfiguration?.offeringType)}
        show={!loadingData && dismissPreferredSupplierAlert}
        cancel={() => onCancel()}
        backdrop="static"
      >
        <Modal.Body>
          {packageConfiguration ? (
            <InternalPackageConfiguration
              redirectToPreferredSupplier={redirectToPreferredSupplier}
              currentEmployer={currentEmployer}
              dmeOrder={dmeOrder}
              packageConfiguration={packageConfiguration}
              useFeetForHeight={useFeetForHeight}
              remove={remove}
              update={update}
              save={save}
              returnToBrowse={returnToBrowse}
              loading={configLoading || preferredSuppliersLoading}
              step={step || ""}
              changeStep={setStep}
              recalculateStep={() => calculateStep(packageConfiguration)}
              changeHeightUnit={changeHeightUnit}
              goToNextPage={nextPage}
              answerRxSurveyQuestion={submitRxSurveyQuestion}
              showServiceTier={initialData.showServiceTier}
              preferredSuppliers={preferredSuppliers.map((ps) => ps.supplier)}
              yourOrganizationsSuppliersOnly={yourOrganizationsSuppliersOnly}
            />
          ) : (
            <p>
              Something went wrong. We could not load the configuration for this
              package.
            </p>
          )}
        </Modal.Body>
      </Modal>
    </PreferredSupplierContext.Provider>
  )
}

export default withInitialData(fetchInitialPackageConfigurationData)(
  PackageConfiguration
)
