import React from "react"
import Search from "applications/SupplierOrganizationDashboard/components/DashboardFilters/Search"
import { DocumentationOrderQueryParameters } from "./fetchOrders"

const SearchFilter = ({
  value,
  updateSearchParameters,
}: {
  value: string | undefined
  updateSearchParameters(
    updatedParameters: Partial<DocumentationOrderQueryParameters>
  ): void
}) => {
  return (
    <Search
      value={value}
      onChange={async (value) => updateSearchParameters({ searchText: value })}
      placeholder="Search Patient or Order ID"
    />
  )
}

export default SearchFilter
